import React from "react"
import { Helmet } from "react-helmet"
import favicon from "@assets/images/Favicon_birchbrook.jpg"
import { SEO as SEOTypes } from "@utils/globalTypes"
import sombraBold from "@styles/fonts/TypeMates-SombraBold.otf"
import sombraRegular from "@styles/fonts/TypeMates-SombraRegular.otf"
import sombraMedium from "@styles/fonts/TypeMates-SombraMedium.otf"

const SEO = (props: SEOTypes) => {
  const { lang = "en", location } = props
  const metaDescription = props.description || "Birchbrook description"
  const metaTitle = props.title || "Birchbrook"
  const metaImage = props.image || ""
  const twitterCard = props.twitterCard || ""
  const twitterSite = props.twitterSite || ""
  const twitterCreator = props.twitterCreator || ""
  const metaKeywords = props.keywords || ""
  const scrollClass =
    props.location && props.location.startsWith("/employees/") ? "scroll" : null
  return (
    <Helmet bodyAttributes={{ class: scrollClass }}>
      <html className={scrollClass} lang={lang} />
      <title>{metaTitle}</title>
      <link rel="icon" href={favicon} />
      <link rel="canonical" href={location} />
      <link
        rel="preload"
        as="font"
        href={sombraBold}
        type="font/otf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={sombraMedium}
        type="font/otf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={sombraRegular}
        type="font/otf"
        crossOrigin="anonymous"
      />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:creator" content={twitterCreator} />
    </Helmet>
  )
}

export default SEO
